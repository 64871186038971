.p-login {
  
  &__text {
    @include f(17px,$c-black,700,2em);
    margin-bottom: 30px;


    @include mq($screen-sm) {
      @include f(15px,0,0,1.5em);
    }
  }

  &__form-wrapper {
    width:270px;
    margin:auto;
    .c-btn {
      width:90%;
      margin-top:20px;
    }
    .text {
      margin-top:50px;
    }
  }

  &__remember {
    text-decoration: underline;
    font-weight: 400 !important;
  }

}