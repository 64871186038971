h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

a {
	transition: all 0.25s ease 0.1s;
	outline: none;
	box-shadow: none;
	color: inherit;

	&, &:hover, &:focus {
		text-decoration: none;
		cursor: pointer;
	}
}

button {
	outline: none;
	box-shadow: none;
	margin: 0;
	border: none;
	padding: 0;
	cursor: pointer;
}

ul {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
}

input, textarea, select {
	display: block;
	width: 100%;
	outline: none;
	box-shadow: none;
}