.p-contact {

  &__title {
    @include f(26px,$c-black,700);
  }

  &__caption {
    @include f(15px,$c-font,400,2em);
    padding-left: 50px;
    margin-bottom:30px;
    margin-left: 40px;
    position: relative;
    &.no-padding {
      padding-left: 0;
    }
    a {
      color:$c-theme;
      font-weight:700;
    }
    img {
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      left:0;
    }
  }

  &__box {
    background: $c-white;
    padding:30px;
    h2 {
      @include f(22px,$c-theme,900);
      text-align: center;
      margin-bottom:20px;
      display: block;
    }
  }

  &__map {
    border-top:5px solid $c-white;
    .map {

      height:500px;
    }
  }
}