.p-cart {

  &__headline {
  	margin-bottom: 10px;
  	@include f(21px,$c-theme,700,1em);
  }

  &__hr {
  	margin: 10px 0 20px;
  	border-top: 1px solid $c-border;
  }

  &__delivery {
  	@include f(17px,$c-font,700,50px);
  }

  &__delivery-price {
  	margin-left: 10px;
  	@include f(34px,$c-theme,700);
  }

  &__summary {
  	@include f(16px,$c-font,500);
  }

  &__final-price {
  	display: block;
  	@include f(40px,$c-theme,900,40px);
  }

	&__table.table {
		@include f(16px,$c-font,400);

	  > tbody {
	    > tr {
	      > td {
	      	vertical-align: middle;
	      	padding: 15px 25px;
	      	background: $c-border;
	      }
	      > .image {
	      	width: 100px;

	        img {
	        	min-width: 50px;
	        }
	      }
	      > .text {
	      	@include f(16px,$c-font,400);
	      }
	      .invalid-info {
	      	padding: 5px 10px;
	      	@include f(11px,$c-warning);
	      	background: $c-warning;
	      }
	      > .price {
	        span {
	        	display: inline-block;
	        	vertical-align: middle;
	        	margin-bottom: 8px;
	        	margin-left: 8px;
	        	@include f(30px,$c-font,700);
	        }
	      }
	      > .quantity { 
	        input {
	          outline: none;
	          box-shadow: none;
	        	display: inline-block;
	        	width: 80px;
	        	margin: 0;
	        	border: 1px solid darken($c-border,5%);
	        	padding: 0 0 0 10px;
	        	@include f(16px,$c-font,400,55px);
	        	background: transparent;
	        }
	        label {
	        	margin-left: 5px;
	        	@include f(16px,$c-font,400);
	        }
	      }
	      > .summary {
	      	width: 210px;
	      	text-align: right;

	        span {
	        	display: inline-block;
	        	vertical-align: middle;
	        	margin-bottom: 8px;
	        	margin-left: 8px;
	        	@include f(30px,$c-theme,700);
	        }
	      }
	    }
	  }


	  @include mq($screen-sm) {
	    > tbody {
	      > tr {
	        td {
	        	padding: 10px 15px;
	        }
	        > .image {
	        	width: initial;
	        }
	        > .summary {
	        	width: initial;
	        }
	      }
	    }
	  }
	}

	&__table-footer {
		padding: 8px 25px;
		@include f(16px,$c-font,400);

	  input {
	  	transition: all 0.25s ease;
	  	height: 50px;
	  	border: 1px solid $c-border;
	  	border-radius: 0;
	  	padding: 0 15px;
	    @include f(18px,$c-theme,400,50px);
	    @include placeholder($c-theme);
	  	background: transparent;

	    &.invalid { border-color: $c-danger; }
	    &.valid { border-color: $c-success; }
	  }

	  .coupon-form {
	    .description {
	    	margin-top: 5px;
	    	@include f(12px,$c-theme);
	    }
	  }

	  span {
	  	display: inline-block;
	  	vertical-align: middle;
	  	margin-left: 8px;
	  	@include f(30px,$c-theme,700);
	  }


	  @include mq($screen-sm) {
	    padding: 8px 0;
	  }
	}
}



