.form-standard {
  $_height: 45px;
  $_border: 1px solid #ddd;
  $_border-color-focus: $c-theme;
  $_border-radius: 30px;
  $_padding: 20px;
  $_font-size: 14px;
  $_color: $c-font;
  $_background: $c-white;

  .form-group {

    &--bootstrap-select {
      @include createBootstrapSelect($_height, $_border, $_border-radius, $_border-color-focus, $_padding, $_font-size, $_color, $_background);
    }

    &--select {
      select {
        -webkit-appearance: none;
      }

      position: relative;
      &::after {
        content: '\f078';
        font-family: "FontAwesome";
        position: absolute;
        bottom: calc(#{$_height} - 20px);
        @include size(20px);
        right: calc(#{$_height}*.4 - 2px);
        @include f(13px,$_color,0,$_height);
      }

    }

    &--number {
      position: relative;
      input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      .up, .down {
        position: absolute;
        right: 18px;
        bottom: 0;
        cursor: pointer;
        @include f(13px,$c-theme,0,20px);
        text-align: center;
        @include size(20px);
        transition: all 0.2s ease;

        &:hover,&:focus {
          background: $c-theme;
          color: $c-white;
        }
      }
      .up {
        transform: translateY(-100%);
        margin-bottom: 4px;
      }
      .down {
        margin-top: 2px;
      }
    }
  }

  label {
    display: inline-block;
    margin: 0 0 7px;
    @include f(14px,$c-font,500,0,uppercase);
  }

  input,
  textarea,
  select {
    transition: all 0.25s ease;
    height: $_height;
    border: $_border;
    border-radius: $_border-radius;
    padding: 0 $_padding;
    @include f($_font-size,$_color,400,$_height,0,$ff-asap);
    @include placeholder(#999999);
    background: $_background;
    font-style: italic;

    &:focus {
      border-color: $_border-color-focus;
    }
  }
  textarea {
    resize: none;
    height: 150px;
    padding: $_padding;
    border-radius: 20px;
    line-height: initial;
  }

  input[type="submit"],
  button[type="submit"] {
    //display: inline-block;
    //@include size(initial);
    //border: none;
    //padding: 0 40px;
    //@include f(18px,$c-white,400,$_height);
    //background: $c-theme;
    //
    //&:hover {
    //
    //}
  }


  // .select-wrapper { position: relative; overflow: hidden; border: $_border; border-radius: $_border-radius;
  //   select { width: calc(100% + 30px); border: none; appearance: none; -webkit-appearance: none; }
  //   &:after { content: ""; position: absolute; top: ($_height - 8px)/2; right: ($_height * .4); @include triangle(down,8px,$_color); }
  // }
  @include createCheckboxes(24px, $_background, $_border, $_color);
  // @include createBootstrapSelect($_height, $_border, $_border-radius, $_border-color-focus, $_padding, $_font-size, $_color, $_background);
  // @include createFileButton($_height, $_padding, $c-theme, 16px, $c-white);



  .required > label:after {
    content: " *";
  }

  .has-error {
    input,
    input[type="radio"] + label span,
    input[type="checkbox"] + label span,
    textarea,
    select {
      border-color: $c-danger;
    }

    input[type="radio"] + label,
    input[type="checkbox"] + label {
      color: $c-danger;
    }

    .form-error-desc {
      padding: 5px 15px;
      @include borderBottomRadius(4px);
      @include f(12px,$c-white,400);
      text-align: left;
      background: $c-danger;
    }
  }
}

.form-search {
  $_input-height: 45px;
  $_border: 0 solid transparent;
  $_border-radius: 0;
  $_color: $c-font;
  $_background: $c-white;

  position: relative;


  input[type="text"] {
    width: 100%;
    padding-right: 30px;

    &:focus {
      border-color: $c-theme;
    }
  }

  button[type="submit"] {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 15px;
    background: transparent;
    padding:0;
    @include f(20px,#6b6b6b,400,$_input-height);

  }
}

.form-dropdown { //require uncomment bootstrap-select
  $_background: #ddd;
  $_color: #000;
  $_focus: red;

  .form-group {
    display: inline-block;
    margin-bottom: 0;
  }

  button.dropdown-toggle {
    background: transparent!important;
    outline: none!important;
    box-shadow: none!important;
    border: none!important;
    color: $_color!important;
    &:focus {
      background: transparent!important;
      outline: none!important;
      box-shadow: none!important;
    }
    .bs-caret {
      display: none;
    }

    position: relative;
    &::after {
      content: '\f107';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      font-family: "FontAwesome";
    }
  }

  .dropdown-menu {
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: $_background;
    ul {
      li {
        a {
          line-height: normal;
          margin: 0;

          &:focus {
            outline: none!important;
          }
          &:hover,&:focus {
            background-color: $_focus;
          }
          span {
            margin:0;
          }
        }
      }
    }
  }
}