.l-footer {

  background: $c-footer;

  &__logo {
    margin-top:20px;
    margin-bottom:20px;
  }

  &__title {
    margin-bottom:30px;
    @include f(20px,$c-theme,700);
  }

  &__right {
    text-align: right;
    @include mq($screen-sm) {
      text-align: left;
    }
  }

  &__caption {
    padding-left: 45px;
    position: relative;
    margin-bottom:20px;
    @include f(15px,$c-white,900);

    img {
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      right:75%;
      @include mq($screen-lg) {
        right:60%;
      }
      @include mq($screen-md) {
        right:75%;
      }
      @include mq($screen-sm) {
        right:auto;
        left:0;
      }
    }
    p {
      @include f(31px,$c-white,900);
      margin-bottom:0;
      @include mq($screen-lg) {
        @include f(20px)
      }
    }
  }

  &__column {
    ul {
      list-style-type: none;
      li {
        a {
          @include f(14px,$c-white);
        }
      }
    }
  }
}

.cpr {
  $_logo-height: 14px;

  border-top: 1px solid $c-border;
  padding: 20px 0;
  @include f(13px,#979797,400,($_logo-height * 1.5));
  background: $c-white;

  @include mq($screen-sm) {
    text-align: center;
  }

  a:hover,
  a:focus {
    color: $c-theme;
  }

  &__right {
    text-align: right;


    @include mq($screen-sm) {
      padding: 8px 0 0;
      text-align: center;
    }
  }

  &__link {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 7px;
    font-size: 0;
    line-height: 0px;

    img {
      vertical-align: baseline;
      height: $_logo-height;
    }
  }
}