// Bootstrap 3 responsive columns of same height
//
// Based on Riccardo Caroli's http://www.minimit.com/articles/solutions-tutorials/bootstrap-3-responsive-columns-of-same-height
//
// Example:
// <div class="row">
//    <div class="row-xs-height">
//       <div class="col-xs-6 col-xs-height col-xs-middle">
//          <div class="col-inside-full-height"></div>
//       </div>
//       <div class="col-xs-6 col-xs-height col-xs-bottom">
//          <div class="col-inside-full-height"></div>
//       </div>
//    </div>
// </div>
//
// Restrictions:
// With column of same height in effect, you can have only one line of columns in the same row, and the line of columns is 100% of the row.
// Applying Boostrap "offset" breaks the layout. "Push" and "pull" work but not in IE.


.col-inside-full-height {
  // If you want to give content full height give it 'height: 100%'.
  // With content full height you can't apply margins to the content.
  // Content full height does not work in IE: http://stackoverflow.com/questions/27384433/ie-display-table-cell-child-ignores-height-100
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

@include make-grid-height(xs);

@media (min-width: $screen-sm-min) {
	@include make-grid-height(sm);
}

@media (min-width: $screen-md-min) {
	@include make-grid-height(md);
}

@media (min-width: $screen-lg-min) {
	@include make-grid-height(lg);
}

@media (min-width: $screen-xlg-min) {
	@include make-grid-height(xlg);
}

@media (min-width: $screen-xxlg-min) {
	@include make-grid-height(xxlg);
}