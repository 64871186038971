.c-btn {
	transition: all .25s ease .1s;
	display: inline-block;
	min-width: 165px;
	outline: none;
	box-shadow: none;
	margin: 0;
	border: none;
	padding: 0 15px;
	cursor: pointer;
	@include f(15px,$c-white,700,40px);
	text-align: center;
	background: $c-theme;
	border-radius: 50px;

	&:hover, &:focus {
		color: $c-white;
		background: lighten($c-theme,7%);
	}

	&--dark {
		background-color: $c-dark;
	}


}
