.c-product {
  background-color: $c-white;
  text-align:center;
  padding:30px 0;
  margin-bottom:30px;

  &__image {
    margin-bottom:20px;
  }

  &__title {
    @include f(14px,$c-font,500,1.7em);
    padding:0 15px;
  }

  &__caption {
    @include f(12px,$c-gray-light);
    margin:15px 0;
  }

  &__price {
    @include f(19px,$c-theme,900);
    span {
      @include f(13px,$c-font,400);
      text-decoration: line-through;
      margin-left: 15px;
      display: inline-block;
    }
  }

  &__link {
    margin-top:15px;
    color: #646464;
    background-color: $c-bg;
    border:1px solid #bbb;
    border-radius:50px;
    padding:0 35px;
    line-height:35px;
    display: inline-block;

    &:hover {
      background: lighten($c-theme,7%);
      color: $c-white;
    }
  }

  &--border {
    border: 1px solid $c-border;
  }
}