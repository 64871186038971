.p-home {

  &__categories {
    padding-top:0;
    margin-top:-100px;
    z-index: 2;
    position: relative;
    @include mq($screen-md) {
      margin-top:0;
      padding-top:40px;
    }
  }

  &__categories .item {

    $_offset: 30px;
    &__img {
      position: relative;
      display: block;
      overflow: hidden;
      border:5px solid $c-white;

      .c-btn {
        position: absolute;
        bottom:$_offset;
        left:$_offset;
        right:$_offset;
        @include f(17px,$c-white,700,55px,uppercase);
        img {
          margin-right: 15px;
        }
        @include mq($screen-lg) {
          @include f(15px)
        }
        @include mq($screen-md) {
          @include f(12px,0,0,40px);
          bottom:$_offset/2;
          left:$_offset/2;
          right:$_offset/2;
          img {
            display: none;
          }
        }
      }

      > img {
        transition: all .25s ease;
        width:100%;
      }

      &:hover {
        > img {
          transform: scale(1.1);
        }
      }
    }

    &__text {
      margin-top:30px;
      text-align: center;
    }
  }


  &__shipping {
    $_h:231px;
    $_w:268px;
    $_s:1;
    position: relative;
    background-color: $c-theme;
    padding-left: 180px;
    margin-bottom:14px;
    &::after {
      $_h:231px;
      $_w:268px;
      $_s:1;
      position: absolute;
      content: '';
      top:-70px;
      right:30px;
      @include size($_w * $_s, $_h * $_s);
      background-image: url(#{$img}/banners/shipping.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    header h2 {
      @include f(32px,$c-white,400,90px);
      span {
        @include f(40px,$c-white,700);
      }
    }

    img {
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      left:45px;
    }

    @include mq($screen-lg) {
      padding-left: 130px;
      margin-bottom:0;
      header h2 {
        @include f(26px);
        span {
          @include f(30px);
        }
      }
      img {
        left:25px;
      }
      &::after {
        $_s:0.9;
        @include size($_w * $_s, $_h * $_s);
      }
    }
    @include mq($screen-md) {
      &::after {
        display: none;
      }
    }
    @include mq($screen-sm) {
      padding:15px 10px;
      text-align: center;
      header h2 {
        @include f(20px,0,0,1.7em);
        span {
          @include f(24px);
        }
      }
      img {
        display: none;
      }
    }
  }


  &__boxes {

  }

  &__boxes-item {
    text-align: center;
    margin-bottom:60px;
  }

  &__boxes-image {
    @include size(110px);
    @include flexAlign();
    background: $c-theme;
    border-radius: 50%;
    margin:auto;
    border: 1px solid #636363;
    position: relative;
    margin-bottom:40px;
    margin-top:15px;
    &::after {
      $offset:15px;
      content: '';
      position: absolute;
      top: -$offset;
      left:-$offset;
      @include size(calc(100% + 2*#{$offset}));
      border-radius: 50%;
      border: 1px solid $c-border;
    }
  }

  &__boxes-title {
    @include f(16px,$c-black,900,0,uppercase);
    @include hasLine(55px,1px,15px,#636363);
  }

  &__about {

  }

  &__about-image {
    margin-top:80px;
  }
}