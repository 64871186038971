.title {
	margin-bottom: 35px;
	@include f(27px,$c-theme,900,1em,uppercase);
}

.text { 
	margin-bottom: 15px;
	@include f(15px,$c-font,400,2em);
	text-align: justify;

	&__title {
		@include f(24px,$c-theme,900);
		margin-bottom:30px;
	}

	p {
		margin-bottom:30px;
	}

	a {
		color: $c-theme;
		font-weight:700;
	}

	img {
		margin: 0 25px 20px 0;
	}

	ul {
		padding-left: 45px;

		li {
			$_list-style-size: 10px;

			display: block;
			position: relative;
			margin-bottom: 10px;

			&:before {
				content: "";
				position: absolute;
				left: -20px;
				top: calc(50% - (#{$_list-style-size} / 2));
				@include size($_list-style-size);
				border-radius: 50%;
				background: $c-theme;
			}
		}
	}

	table {
		width: 100%;
		border-spacing: 5px;
		border-collapse: separate;

		tr {
			th {
				padding: 10px 15px;
				color: $c-white;
				background: $c-theme;
			}

			td {
				border: 1px solid $c-theme;
				padding: 10px 15px;
			}
		}
	}
}