#tabs {
  ul {
    list-style: none;
    text-align: center;
    margin-bottom:30px;

    li {
      display: inline-block;
      a {
        background: transparent;
        color: $c-dark;
        @include f(15px,$c-dark,700,0,uppercase)
      }
      &.ui-state-active a {
        background: $c-dark;
        color:$c-white;
      }
      &.ui-state-focus a {
        &:focus {
          outline:none!important;
        }
      }
    }
  }
}