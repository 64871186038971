.l-partners {

  padding:35px 0;
  img {
    width:auto!important;
    vertical-align: middle;
    text-align: center;
    margin:auto;
  }
  .owl-item {
    text-align: center;
  }

  @include mq($screen-sm) {
    padding:10px 0;
  }
}