.p-page {
  &__title {
    background-color: $c-bg;
    padding:40px 0;

    h1 {
      @include f(26px,$c-black,700);
      @include mq($screen-sm) {
        @include f(19px)
      }
    }

    @include mq($screen-sm) {
      padding:20px 0;
    }
  }
}