.l-socials {
  $social-types: (
      facebook:     $c-facebook,
      instagram:    $c-instagram,
      youtube:      $c-youtube,
      pinterest:    $c-pinterest,
      google-plus:  $c-google-plus,
      linkedin:     $c-linkedin,
      twitter:      $c-twitter,
  );
  $_radius: 50%;
  $_color: $c-white;
  $_size: 40px;
  $_marginRight:15px;

  margin-bottom:30px;

  a {
    @include size($_size);
    @include f(20px,$_color,700,$_size);
    border-radius: $_radius;
    display: inline-block;
    text-align: center;
    margin-right: $_marginRight;

    i {
      @include flexAlign();
      @include size(100%);
    }
  }

  @each $name, $value in $social-types {
    a.#{$name} {
      background-color: $value;
      border:2px solid $value;
      &:hover  {
        color: $value;
        border-color: $value;
        background: $c-white;
      }
    }
  }

}