.c-alert {
	$_button-width: 24px;
	$alert-types: (
	  success: $c-success,
	  danger: $c-danger,
	  warning: $c-warning,
	  info: $c-info
	);

	transition: opacity .5s ease .1s, visibility .5s ease .1s, max-height .3s ease .5s;
	max-height: 200px; // for transition
	overflow: hidden;
	text-align: left;

	&.is-closed {
		opacity: 0;
		visibility: hidden;
		max-height: 0;
	}

	&__inner {
		position: relative;
		padding: 17px (20px+$_button-width) 13px 15px;
		border: 0;
		border-radius: 4px;
		margin: 0 0 15px;
		@include f(14px,$c-white,400);

		@each $name, $value in $alert-types {
		  &--#{$name} {
				background: $value;
				border-bottom: 1px solid darken($value,7%);
		  }
		}
	}

	&__button {
		transition: all .25s ease;
		position: absolute;
		top: calc(50% - 12px);
		right: 15px;
		@include size($_button-width);
		padding: 0;
		opacity:.8;
		@include f(24px,$c-white,300,26px);
		text-align: center;
		background: transparent;
		cursor: pointer;

		&:hover, &:focus {
			opacity:1;
		}
	}
}