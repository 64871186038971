$grid-columns: 		12;
$grid-gutter-width: 30px;

$screen-sm-min: 	768px;
$screen-md-min: 	993px;
$screen-lg-min: 	1200px;
$screen-xlg-min: 	1500px;
$screen-xxlg-min: 	1800px;

$container-xlg: 	1470px;
$container-xxlg: 	1770px;