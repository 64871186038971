// Bootstrap Select v1.11.2 (2016-09-09)


@mixin createBootstrapSelect($_height, $_border, $_border-radius, $_border-color-focus, $_padding, $_font-size, $_color, $_background) {
	.bootstrap-select {
		> .dropdown-toggle {
			box-shadow: none;
			height: $_height;
			@include f($_font-size,$_color,0,$_height);
			padding: 0 $_padding;
			border: $_border;
			border-radius: $_border-radius;
			background-color: $_background;

			&:hover { background-color: $_background; }
			&:focus { outline: none!important; }
		}
		.form-control:focus { outline: none; box-shadow: none; }
		.text { margin-bottom: 0; }

		.dropdown-menu { border: $_border; border-radius: $_border-radius;
			&.inner { padding: 4px 8px 0; }

			// options
			> li > a { padding-left: $_padding; padding-right: $_padding; }
			> li > a .glyphicon { color: $c-theme; }
			> .active > a,
			> .active > a:hover,
			> .active > a:focus { background-color: $c-theme;
				.text { color: #fff; }
			}
		}

		// search input
		input[type="text"] {
			height: 30px;
			padding: 0 10px;
			color: #000;
			line-height: 30px;
			background: #fff;
		}
	}

	.btn-default {
		transition: all 0.25s ease;
		position: relative;
		color: inherit;

		&:hover, &:focus, &:active, &.focus, &.active { background-color: $_background; }
		&.active:hover,	&.active:focus,	&.active.focus,
		&:active:hover, &:active:focus, &:active.focus { border-color: $_border-color-focus; }

		&:after {
			content: '\f078';
			font-family: "FontAwesome";
			position: absolute;
			top: 0;
			@include size(20px);
			right: $_height*.4;
			@include f(13px,$_color,0,$_height);
		}
		
		// triangle
		.bs-caret { display: none; }
	}

	.open > .dropdown-toggle.btn-default {
		background-color: $_background;

		&:hover, &.focus, &:focus {
			border-color: $_border-color-focus;
			color: $_color;
			background-color: $_background;
		}
	}
}