// Fonts


@mixin fontFace($name: "Asap", $italic: false) {

	@if $name == "Asap" {
		@font-face { src: url(#{$fonts}/asap/Asap-Regular.otf);				font-family: "Asap"; font-weight: 400; }
		@font-face { src: url(#{$fonts}/asap/Asap-Medium.otf);				font-family: "Asap"; font-weight: 500; }
		@font-face { src: url(#{$fonts}/asap/Asap-Bold.otf);					font-family: "Asap"; font-weight: 700; }
		@if $italic != false {
		@font-face { src: url(#{$fonts}/asap/Asap-Italic.otf);				font-family: "Asap"; font-weight: 400; font-style: italic; }
		@font-face { src: url(#{$fonts}/asap/Asap-MediumItalic.otf);	font-family: "Asap"; font-weight: 500; font-style: italic; }
		@font-face { src: url(#{$fonts}/asap/Asap-BoldItalic.otf);		font-family: "Asap"; font-weight: 700; font-style: italic; }
		}
	}

	@if $name == "Avenir" {

		
	}

	@if $name == "AvenirCN" {
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-UltLtCn.otf);			font-family: "Avenir"; font-weight: 100; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-Cn.otf); 					font-family: "Avenir"; font-weight: 400; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-MediumCn.otf);		font-family: "Avenir"; font-weight: 500; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-DemiCn.otf);			font-family: "Avenir"; font-weight: 600; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-BoldCn.otf);			font-family: "Avenir"; font-weight: 700; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-HeavyCn.otf);			font-family: "Avenir"; font-weight: 900; }
		@if $italic != false {
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-UltLtCnIt.otf);		font-family: "Avenir"; font-weight: 100; font-style: italic; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-CnIt.otf); 				font-family: "Avenir"; font-weight: 400; font-style: italic; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-MediumCnIt.otf);	font-family: "Avenir"; font-weight: 500; font-style: italic; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-DemiCnIt.otf);		font-family: "Avenir"; font-weight: 600; font-style: italic; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-BoldCnIt.otf);		font-family: "Avenir"; font-weight: 700; font-style: italic; }
		@font-face { src: url(#{$fonts}/avenir/cn/AvenirNextLTPro-HeavyCnIt.otf);		font-family: "Avenir"; font-weight: 900; font-style: italic; }
		}
	}

}
