.c-side {
  @include mq($screen-sidr) {
    display: none;
  }

  background-color: $c-white;
  padding-bottom: 50px;

  &__title {
    @include f(18px,$c-theme,900,100px,uppercase);
    text-align: center;
    margin-bottom:0;
    &:not(.sm) {
      @include mq($screen-sidr) {
        display: none;
      }
    }

    &.sm {
      line-height: 50px;
      font-size: 16px;
      text-align: left ;
      margin-left: 55px;
      margin-top:40px;
    }
  }

  &__btn {
    width: 100%;
    @include f(20px,$c-white,700,50px,uppercase);
    background-color: $c-theme;
    margin-bottom: 15px;
  }

  ul { margin: 0; padding: 0; font-size: 0; }
  ul li { position: relative; vertical-align: bottom; display: block; }
  ul li a {
    position: relative; display: block; cursor: pointer;
    &:hover {
      color: $c-theme;
    }
  }

  > ul {
    > li {
      background: $c-white;
      padding-left: 30px;
      > a {
        @include f(17px,$c-font,700,40px,uppercase);

        span {
          @include f(12px,0,400);
          font-style: italic;
        }

        &:hover {
          color: $c-theme;
        }
      }
    }
  }

  ul ul {
    padding-left:15px;
    margin-bottom:20px;
    > li {
      > a {
        @include f(14px);
      }
      &:before {
        $_list-style-size: 4px;
        content: "";
        position: absolute;
        left: -15px;
        top: calc(50% - (#{$_list-style-size} / 2));
        @include size($_list-style-size);
        border-radius: 50%;
        background: $c-black;
      }
    }
  }


  &__filters {
    > li {
      position: relative;
      padding-left: 55px!important;
      > a {
        font-size: 14px!important;
        line-height: 30px!important;
      }
      &:before {
        $_list-style-size: 16px;
        content: "";
        position: absolute;
        left: 30px;
        top: calc(50% - (#{$_list-style-size} / 2));
        @include size($_list-style-size);
        border-radius: 50%;
        background: transparent;
        border:2px solid #bcbcbc;
      }
    }
  }

  .js-toggle-list {
    @include f(14px,$c-black,900);
    text-decoration: underline;
    margin:15px 30px;
    display: block;
  }
}