// Layout with different number of columns
//
// Based on http://stackoverflow.com/questions/10387740/five-equal-columns-in-twitter-bootstrap/22799354#22799354


@mixin make-grid-custom-columns($number) {
	$list: ".col-xs-#{$number}ths, .col-sm-#{$number}ths, .col-md-#{$number}ths, .col-lg-#{$number}ths, .col-xlg-#{$number}ths, .col-xxlg-#{$number}ths";
	#{$list} {
		position: relative;
		// Prevent columns from collapsing when empty
		min-height: 1px;
		// Inner gutter via padding
		padding-left:  ceil(($grid-gutter-width / 2));
		padding-right: floor(($grid-gutter-width / 2));
	}
}

@mixin make-custom-grid($class, $number) {
	.col-#{$class}-#{$number}ths {
		float: left;
		width: percentage((1 / $number));
	}
}