// Layout with different number of columns.
//
// Based on http://stackoverflow.com/questions/10387740/five-equal-columns-in-twitter-bootstrap/22799354#22799354


@include make-grid-custom-columns(5);

@include make-custom-grid(xs, 5);

@media (min-width: $screen-sm-min) {
	@include make-custom-grid(sm, 5);
}

@media (min-width: $screen-md-min) {
	@include make-custom-grid(md, 5);
}

@media (min-width: $screen-lg-min) {
	@include make-custom-grid(lg, 5);
}

@media (min-width: $screen-xlg-min) {
	@include make-custom-grid(xlg, 5);
}

@media (min-width: $screen-xxlg-min) {
	@include make-custom-grid(xxlg, 5);
}