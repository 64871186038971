.breadcrumbs {

  padding:20px 0;
  border-top:3px solid $c-bg;
  a {
    display: inline-block;
    @include f(14px,$c-breadcrumbs,0,40px);
  }
  i {
    margin: 0 15px;
  }

  &__button-back {
    border-radius: 30px;
    @include f(15px,$c-theme,700,40px);
    color: $c-theme!important;
    background-color: $c-bg;
    padding:0 25px;
    @include mq($screen-sm) {
      display: none!important;
    }
  }

  @include mq($screen-sm) {
    padding:0;
  }
}