 @include fontFace($name: "Asap", $italic: false);
// @include fontFace($name: "Avenir", $italic: false);

html, body {
	font-family: $ff-default, sans-serif;
	@include f($default-font-size,$c-font);
	overflow-x: hidden;
}

.main-content {
	padding:50px 0;
	@include mq($screen-md) {
		padding:20px 0;
	}
}
.bg-gray {
	background-color: $c-bg;
}

@include mq($screen-sidr) {
	.hidden-sidr {
		display: none!important;
	}
}

.visible-sidr {
	display: none!important;

	@include mq($screen-sidr) {
		display: block!important;
	}
}