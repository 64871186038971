// Bootstrap 3 responsive columns of same height
//
// Based on Riccardo Caroli's http://www.minimit.com/articles/solutions-tutorials/bootstrap-3-responsive-columns-of-same-height


@mixin make-row-height($class) {
  .row-#{$class}-height {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }
}

@mixin make-column-height($class) {
  .col-#{$class}-height { display: table-cell; float: none; height: 100%; }
  .col-#{$class}-top    { vertical-align: top; }
  .col-#{$class}-middle { vertical-align: middle; }
  .col-#{$class}-bottom { vertical-align: bottom; }
}

// Create grid with columns of same height.
@mixin make-grid-height($class) {
  @include make-row-height($class);
  @include make-column-height($class);
}