.top {
	padding:5px 0;
	border-bottom:1px solid $c-border;
	@include f(15px,$c-font,400,50px,uppercase);
	span {
		@include f(17px);
		margin-left: 10px;
	}

	&__icons {
		display: flex;
		justify-content: space-between;
		p {
			display: inline-block;
			margin-bottom:0;
		}
		.item {
			img {
				margin-right: 15px;
				vertical-align: middle;
			}
		}
		@include mq($screen-sidr) {
			justify-content: space-around;
			p {
				display: none;
			}
		}
	}
}

.header {

	padding-top: 30px;
	padding-bottom:15px;
	@include mq($screen-sidr) {
		padding:0;
	}

	&__nav {
		margin-top:40px;
	}

	&__item {
		position: relative;
		padding-left: 50px;
		display: inline-block;
		img {
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:-15px;
		}
		a {
			@include f(19px,$c-theme,700);

		}
		span {
			@include f(13px,$c-gray,400);
		}
	}

	&__right {
		text-align: right;
	}

	.logo {
		@include mq($screen-sidr) {
			padding: 15px 0;
			text-align: center;

			img {
				height: 45px;
			}
		}
		@include mq($screen-sm) {
			text-align: right;
		}
	}

}