@include createGallery();


.p-product {

	// MAIN

	&__add {
		@include f(19px,$c-white,700,55px);
		padding:0 40px;
		margin-bottom:30px;
		img {
			margin-right: 10px;
			vertical-align: text-bottom;
		}
	}

	&__attributes {

	}

	&__attributes-group {
		margin-bottom:10px;
	}

	&__attributes-name {
		@include f(14px,$c-black,700);
	}

	&__attributes-value {
		@include f(14px,$c-black,400);
	}

	&__category {
		@include f(16px, $c-font);
		margin-bottom: 35px;
	}

	&__price {
		@include f(32px,$c-theme,900);
	}

	&__price-old {
		@include f(17px,$c-black,700);
		text-decoration: line-through;
	}

	&__price-label {
		@include f(17px,$c-black,400)
	}

	&__quantity {
		@include createQuantity(130px);
		//project specific
		margin-bottom:30px;
		$offset: 30px;
		input {
			font-style: normal;
			text-align: center;
		}
		.up, .down {
			top: 50%;
			transform: translateY(-50%);
			font-size: 0;
			bottom:auto;
			right:auto;
			@include size(auto);
		}
		.up 	{ left: 	-$offset; }
		.down 	{ right: 	-$offset; }
	}

	&__separator {
		margin-top: 20px;
	}

	&__title {
		@include f(20px, $c-black, 700, 1.4em);
	}


	@include mq($screen-md) {
		&__title {
			@include f(17px);
		}
		&__quantity {
			margin-top:30px;
			margin-bottom:10px;
		}
		&__add {
			margin-bottom:10px;
			@include f(15px)
		}
	}


	// ADDITIONAL

	&__box {
		background-color: $c-white;
		padding:15px;
		margin:40px 0;
		&-img {
			line-height:40px;
		}
	}

	&__link {
		width:180px;
		margin:auto;
		position: relative;
		margin-bottom:15px;
		a {
			@include f(14px, $c-gray, 400);
			text-decoration: underline;
			margin-left: 40px;
		}
		img {
			position:absolute;
			top:50%;
			transform: translateY(-50%);
			left:0px;
		}
	}

	&__nav {
		border-bottom:2px solid $c-border;
		margin-bottom:30px;
		ul {
			display: flex;
			justify-content: flex-start;
			list-style-type: none;
			margin-bottom:30px;
			li {
				margin-right: 70px;
				a {
					@include f(14px,$c-black,700,0,uppercase);
					&.current, &:hover, &:focus {
						color: $c-theme;
					}
				}
			}
		}
	}

	&__variant {
		@include f(14px,$c-font,400,40px);
		background-color: $c-bg;
		padding:0 20px;
		border-radius: 50px;
		text-align: center;
	}

	@include mq($screen-md) {
		&__nav {
			ul { margin-top:20px; }
		}
	}
	@include mq($screen-sm) {
		&__nav {
			ul {
				margin-bottom:15px;
				justify-content:space-between;
				li {
					margin-right: 0px;
					a {
						@include f(12px)
					}
				}
			}
		}
	}
}